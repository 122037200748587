<template>
    <div class="container">
        <div class="head">
            <template v-if="!userId">
                注册成为{{formData.userType == 2 ? '艺人' : formData.userType == 3 ? '场地' : formData.userType == 5 ? '厂牌' : '用户'}}
            </template>
            <template v-else>完善基本信息</template>
        </div>
        <div class="register-box">
            <div class="register-container">
                <el-form
                    :model="formData"
                    :rules="rules"
                    ref="formData"
                    label-position="top"
                    v-loading="dataLoading"
                >
                    <div class="page-group-title">基本信息</div>
                    <el-row :gutter="24">
                        <el-col :sm="12">
                            <el-form-item prop="name" label="名称">
                                <el-input v-model="formData.name" placeholder="请输入名称" size="medium" maxlength="40"></el-input>
                            </el-form-item>
                        </el-col>

                        <!-- 艺人注册 -->
                        <template v-if="formData.userType == 2">
                            <el-col :sm="12">
                                <el-form-item prop="type" label="艺人形式">
                                    <el-select v-model="formData.type" placeholder="请选择艺人形式" size="medium">
                                        <el-option :value="1" label="个人"></el-option>
                                        <el-option :value="2" label="团体"></el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                            <el-col :sm="12">
                                <el-form-item prop="memberCount" label="成员人数">
                                    <el-input-number style="width:100%" v-model="formData.memberCount" :precision="0" controls-position="right" :min="1" :max="99999999" size="medium" placeholder="请输入成员人数"></el-input-number>
                                </el-form-item>
                            </el-col>
                            <el-col :sm="12">
                                <el-form-item prop="birthday" label="组建年份">
                                    <el-date-picker 
                                        v-model="formData.birthday" 
                                        type="year" 
                                        value-format="timestamp" 
                                        placeholder="选择年份" 
                                        size="medium" 
                                        :picker-options="pickerOptions"
                                    ></el-date-picker>
                                </el-form-item>
                            </el-col>
                            <el-col :sm="12">
                                <el-form-item prop="styles" label="风格">
                                    <el-cascader
                                        v-model="styles"
                                        :options="styleList"
                                        filterable
                                        :props="{multiple:true,label:'name',children:'tags',value:'id',emitPath:true,checkStrictly:true}"
                                        size="medium"
                                        @change="selectStyles">
                                        <template slot-scope="{ node, data }">
                                            <span>{{ data.name }}</span>
                                            <span v-if="!node.isLeaf"> ({{ data.tags.length }}) </span>
                                        </template>
                                    </el-cascader>
                                </el-form-item>
                            </el-col>
                        </template>

                        <!-- 场地注册 -->
                        <template v-if="formData.userType == 3">
                            <el-col :sm="12">
                                <el-form-item prop="capacity" label="容纳人数">
                                    <el-input-number style="width:100%" v-model="formData.capacity" :precision="0" controls-position="right" :min="0" :max="99999999" size="medium" placeholder="请输入容纳人数"></el-input-number>
                                </el-form-item>
                            </el-col>
                            <el-col :sm="12">
                                <el-form-item prop="siteType" label="场地类型:">
                                    <el-select v-model="formData.siteType" placeholder="请选择场地类型" size="medium">
                                        <el-option v-for="item in siteTypeList" :key="item.id" :label="item.name" :value="item.id"></el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                        </template>


                        <el-col :sm="12">
                            <el-form-item prop="city" label="所在城市">
                                <el-cascader
                                    v-model="formData.city"
                                    :options="cityList"
                                    :props="{label:'name',children:'cityList',value:'code',emitPath:false}"
                                    size="medium">
                                </el-cascader>
                            </el-form-item>
                        </el-col>

                        <template v-if="formData.userType == 3">
                            <el-col :sm="12">
                                <el-form-item prop="contact" label="咨询电话">
                                    <el-input v-model="formData.contact" placeholder="请输入咨询电话" size="medium" ></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :sm="24">
                                <el-form-item prop="longitude">
                                    <span slot="label">坐标地址 <small v-if="formData.longitude" class="color-info">已绑定坐标：{{formData.longitude}},{{formData.latitude}}</small></span>
                                    <el-row :gutter="8">
                                        <el-col :span="3">
                                            <el-button type="primary" size="medium" @click="$refs.selectAddressBaiduMap.show()">绑定坐标</el-button>
                                        </el-col>
                                        <el-col :span="21">
                                            <el-input v-model="formData.address" placeholder="百度地图根据搜索结果返回的地址" size="medium"></el-input>
                                        </el-col>
                                    </el-row>
                                </el-form-item>
                            </el-col>
                            <select-address-baidu-map 
                                ref="selectAddressBaiduMap" 
                                :address.sync="formData.address" 
                                :lng.sync="formData.longitude" 
                                :lat.sync="formData.latitude">
                            </select-address-baidu-map>
                        </template>
                        
                        <el-col :sm="24">
                            <el-form-item prop="avatar" label="头像">
                                <avatar 
                                    :url.sync="formData.avatar" 
                                    :urlFull.sync="formData.avatar" 
                                    :crop="true"
                                    :width="800"
                                    :height="800"
                                    class="avatar"
                                >
                                </avatar>
                            </el-form-item>
                        </el-col>
                    </el-row>   


                    <div class="page-group-title">负责人</div>
                    <el-row :gutter="24">
                        <el-col :sm="12">
                            <el-form-item prop="contactName" label="负责人姓名">
                                <el-input v-model="formData.contactName" placeholder="请输入负责人姓名" size="medium" maxlength="40"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :sm="12">
                            <el-form-item prop="contactMail" label="负责人邮箱">
                                <el-input v-model="formData.contactMail" placeholder="请输入负责人邮箱" size="medium"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :sm="12">
                            <el-form-item prop="telephone">
                                <span slot="label">负责人手机 <small class="color-info">手机可用于接收重要通知</small></span>
                                <el-input v-model="formData.telephone" placeholder="请输入负责人手机" size="medium" @change="changeTelephone"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :sm="12">
                            <el-form-item prop="securityCode" label="短信验证码">
                                <div class="input-with-button">
                                    <el-input v-model="formData.securityCode" placeholder="请输入短信验证码" size="medium">
                                        <el-button
                                            slot="append"
                                            native-type="button"
                                            type="text"
                                            @click="getCode()"
                                            :disabled="codeBtn.disabled"
                                            :loading="codeBtn.loading"
                                        >{{codeBtn.btnText}}</el-button>
                                    </el-input>
                                </div>
                            </el-form-item>
                        </el-col>
                    </el-row>        

                </el-form>

                <div class="btns MT20">
                    <template v-if="formData.userId">
                        <el-button @click="goBack">上一步</el-button>
                        <el-button type="primary" @click="onSubmit" :loading="btnLoading">下一步</el-button>
                    </template>
                    <template v-else>
                        <el-button type="primary" @click="onSubmit" :loading="btnLoading">注册</el-button>
                    </template>
                </div>

            </div>
        </div>

        
    </div>
</template>

<script>
import Avatar from '@/components/ImageUpload'
import SelectAddressBaiduMap from '@/components/SelectAddressBaiduMap'
import {fetchAllTag,fetchAllCity,fetchCode,getMenuList,verificationSwitch,fetchSiteTagList} from '@/assets/js/commonApi'
const defaultFormData = {
    address:'',
    areaCode:'',
    avatar:'',
    birthday:'',
    capacity:'',
    city:'',
    contact:'',
    contactMail:'',
    contactName:'',
    latitude:'',
    longitude:'',
    memberCount:1,
    name:'',
    styles:[],
    tags:[],
    telephone:'',
    type: 1,
    securityCode:'',//短信验证码
    siteType: ""
}
export default {
    components:{Avatar,SelectAddressBaiduMap},
    data(){
        return{
            userId: this.$route.query.userId || '',
            authType:  this.$route.query.authType || 1,  //1：认领，2：注册
            dataLoading: false,
            formData:{
                ...defaultFormData,
                userType: parseInt(this.$route.query.userType || 2)
            },
            rules:{
                name:[{required: true, message: '请输入名称'}],
                memberCount:[{required: true, message: '请输入成员人数'}],
                styles:[{required: true, message: '请选择风格'}],
                city:[{required: true, message: '请选择所在城市'}],
                capacity:[{required: true, message: '请输入容纳人数'}],
                longitude:[{required: true, message: '请通过百度地图选择坐标'}],
                contactName: [{required: true, message: '请输入负责人姓名'}],
                contactMail:[{required: true, message: '请输入负责人邮箱'},{ type: "email", message: "请输入正确的邮箱地址" }],
                telephone: [{ required: true, message: "请输入负责人手机号" },{ validator: this.$utils.validatePhone }],
                securityCode:[{required: true, message: '请输入短信验证码'}],
                siteType:[{required: true, message: '请选择场地类型'}],
            },
            codeBtn: {
                btnText: "获取验证码",
                disabled: false,
                loading: false
            },
            captcha: null,
            redirect: this.$route.query.redirect || '/register',
            btnLoading:false,
            styles:[],
            styleList:[],//所有风格Tag集合
            cityList:[],//所有省份城市集合
            pickerOptions:{
                disabledDate(time) {
                    return time.getTime() > Date.now();
                },
            },
            timer: null,
            siteTypeList:[],
        }
    },
    created(){
        const that = this;
        this.$utils.loadScript(
            {
                id:'tcaptcha',
                url:'https://turing.captcha.qcloud.com/TCaptcha.js'
            },
            () =>{
                // eslint-disable-next-line no-undef
                this.captcha = new TencentCaptcha(
                    "2032912416", 
                    function(res){
						console.log(res.ret)
                        if(res.ret === 0){
						
                            that.getCodeEvent( res );
                        }
                    }
                );
            }
        )
    },
    mounted(){
        if(this.userId){
            this.getUserInfo()
        }else{
            this.formData.city = 10;
        }

        if(this.formData.userType == 2){
            this.getStyleList() //艺人独有
        }
        if(this.formData.userType == 3){
            this.getSiteType() //场地独有
        }
        this.getCityList()
    },
    methods:{
        //获取所有风格
        async getStyleList(){
            const res = await fetchAllTag({}).catch(() => {});
            const{ state, result} = res.data;
            if( state == 1 ){
                this.styleList = result;
            }
        },
        //获取所有城市
        async getCityList(){
            const res = await fetchAllCity({}).catch(() => {});
            const{ state, result} = res.data;
            if( state == 1 ){
                this.cityList = result;
            }
        },
        //选取风格
        selectStyles(){
            // 风格&tag id组装
            let _styles = [], _tags = [];
            this.styles.forEach( item =>  {
                _styles.push( item[0] );
                if(item[1]){
                    _tags.push( item[1] ); 
                }
            })

            this.formData.styles = _styles ? [...new Set(_styles)] : [];
            this.formData.tags = _tags ? [...new Set(_tags)] : [];
        },
        //获取场地类型
        async getSiteType(){
            const res = await fetchSiteTagList({}).catch(() => {});
            const{ state, result} = res.data;
            if( state == 1 ){
                this.siteTypeList = result;
            }
        },
        //获取用户信息
        getUserInfo(){
            this.dataLoading = true;
            this.$request({
                url: "/user/register/getBUserInfo",
                params: {userId:this.userId},
                method: "post",
            })
                .then((res) => {
                    const { state, msg, result } = res.data;
                    if (state == 1 && result) {
                        this.formData = {
                            ...defaultFormData,
                            ...result,
                            city: result.city ? parseInt(result.city) : 10,
                            birthday: result.birthday ? new Date(result.birthday).getTime() : '',
                            telephone:result.telephone && !result.telephone.includes('tmp') ? result.telephone : '',
                            styles: [],
                            tags:[]
                        }
                        // this.styles = result.styles ? result.styles.split(',') : [];
                        // this.styles = this.styles.map(v => [v]);
                        // this.styles = Array.from(this.styles).sort((x,y) => x[0] - y[0]);
                        //风格id
                        if(this.formData.userType == 2 && result.tagVOS){
                            result.tagVOS.map(item => {
                                const _arry = [];
                                _arry.push(item.styleId);

                                this.formData.styles.push(item.styleId);
                                if(item.tagId){
                                    this.formData.tags.push(item.tagId);
                                    _arry.push(item.tagId);
                                }
                                
                                this.styles.push(_arry);
                            })
                            this.styles = Array.from(this.styles).sort((x,y) => x[0] - y[0]);
                        }
                    } else {
                        this.$message.error(msg || "获取数据失败,请重试");
                    }
                })
                .catch(() => {
                    this.$message.error("获取数据失败,请重试");
                })
                .finally(() => {
                    this.dataLoading = false;
                });
        },
        //返回
        goBack() {
            this.$router.push({
                path: this.redirect,
            });
        },
		//验证手机号是否注册,没有注册的才可以获取短信验证码   authType:  this.$route.query.authType || 1,  //1：认领，2：注册
		getCode(){
			var that = this
			that.$refs["formData"].validateField(["telephone"],(err) => {
			    if(!err){					
					that.$request({
					    url: "/user/register/checkRegisterPhone",
					    params: {telephone:that.formData.telephone },
					    method: "post",
					})
					.then(async (res) => {
						const { state, msg ,result } = res.data;
						if (state == 1 ) {
							if( result.isBUserReg == 1 ){								
								that.$message.error(msg || "该手机号已经被其他B类账号占用，请更换手机号！");
							}else if( result.isRegister == 1 && this.authType == 2 ){								
								that.$confirm('当前手机号已被乐迷账户占用，如果继续注册，会将乐迷账户升级为小站账户。之后您可以使用该手机号登录经营平台管理小站，也欢迎您使用该手机号登录APP乐迷版跟乐迷互动！', '提示', {	   
								   closeOnClickModal: false,
								   customClass:'mpop',
								   confirmButtonText: '继续使用该手机号注册',
								   cancelButtonText: '换其他手机号注册',
								}).then(async (res) => {
								   const res1 = await verificationSwitch(); //获取验证码设置
								   const { state, msg, result } = res1.data;
								   if (state != 1) {
								       this.$message.error(msg || "获取验证码设置失败");
								   } else {
								       if( result.suduRegister ){
								           this.captcha.show();
								       }else{
								           this.getCodeEvent();
								       }
								   }
								}).catch(() => {});
							}else if( result.isRegister == 1 && this.authType == 1 ){
									that.$confirm('该手机号已经被其他乐迷账号占用，如果继续认领，<span style="font-size:16px;color:#F00">会将乐迷账号的手机号、实名认证信息迁移到B类账号，之后乐迷账号将无法使用当前手机号登录，同时，乐迷账号的订单、等级信息将不会迁移至B类账号，此流程不可逆，请谨慎操作!</span>，是否继续？', '提示', {	   
									   closeOnClickModal: false,
									   customClass:'mpop',
									   dangerouslyUseHTMLString: true ,
									   confirmButtonText: '继续使用该手机号认领',
									   cancelButtonText: '换其他手机号进行认领',
									}).then(async (res) => {
									   const res1 = await verificationSwitch(); //获取验证码设置
									   const { state, msg, result } = res1.data;
									   if (state != 1) {
										   this.$message.error(msg || "获取验证码设置失败");
									   } else {
										   if( result.suduRegister ){
											   this.captcha.show();
										   }else{
											   this.getCodeEvent();
										   }
									   }
									}).catch(() => {});

								}
							else if( result.isBUserReg == 0 && result.isRegister == 0 ){
								const res1 = await verificationSwitch(); //获取验证码设置
								const { state, msg, result } = res1.data;
								if (state != 1) {
								    this.$message.error(msg || "获取验证码设置失败");
								} else {
								    if( result.suduRegister ){
								        this.captcha.show();
								    }else{
								        this.getCodeEvent();
								    }
								}
							}
						} else {
							that.$message.error(msg || "获取数据失败,请重试");
						}
					})
					.catch(() => {
						that.$message.error("获取数据失败,请重试");
					})
					    
				}
			})
		},       
		
        getCodeEvent(_res){
            this.codeBtn.loading = true;
			
            fetchCode({
                biz:'reg_sub',
                phone: this.formData.telephone,
                randStr:_res && encodeURIComponent(_res.randstr) || '',
                ticket: _res && encodeURIComponent(_res.ticket) || '',
            })
                .then((res) => {
                    const { state, msg } = res.data;
                    if (state != 1) {
                        this.$message.error(msg || "请求失败");
                    } else {
                        // this.formData.securityCode = res.data.result;
                        this.dtime(60)
                    }
                })
                .catch(() => {
                    this.$message.error('请求错误');
                }).finally( () => {
                    this.codeBtn.loading = false;
                });
        },
        dtime( time ){
            var that = this , _time = time;
            if( _time <= 0 ){
                this.codeBtn.btnText = '获取验证码';
                this.codeBtn.disabled = false;
            }else{
                this.codeBtn.btnText =  _time+'秒后重试' ;
                _time --;
                this.codeBtn.disabled = true;
                this.timer = setTimeout(function(){ that.dtime( _time );clearTimeout(this.timer); } , 1000);
            }
        },
        changeTelephone(){
            clearTimeout(this.timer);
            this.dtime(0);
            this.formData.securityCode = "";
        },
        //表单提交 有用户id是认领 /user/register/claim 
		 onSubmit() {
			this.$refs["formData"].validate((valid) => {
			    if (valid) {
			        this.btnLoading = true;
			    
			        this.selectStyles();
			        this.formData.styles = this.formData.styles.join();
			        this.formData.tags = this.formData.tags.join();
					
					const _url = this.userId
					    ? "/user/register/claim"
					    : "/user/register/registerUser";
					
					if( this.userId ){
						this.$router.push({
						    path:'/register/step2',
						    query:{
						        userId: this.formData.userId,
						        userType: this.formData.userType || 2,
						        redirect: this.$router.currentRoute.fullPath,
						        authType: this.authType, //1：认领，2：认证
								id: '',
								step1Data:this.formData
						    }
						});
					}else{
						this.$request({
						    url: _url,
						    method: "POST",
						    data: {...this.formData,systime:new Date().getTime()},
						})
						    .then((res) => {
						        //处理成功回调
						        const { state, msg, result } = res.data;
						        if (state && state == 1) {
						            if(!this.formData.userId){
						                this.regSuccess();
						            }
						            //注册或认领成功后应该自动登录
						            this.$store.commit( 'setCUSUT', result.session.token || null );
						            if(result.isMaster == 1 || result.masterUsers.length == 1){ //主账号或主账号只有一个主账号
						                this.$store.commit('setUserInfo',result.masterUsers[0]);
						                getMenuList();//获取登录用户拥有的菜单权限
						            }
						            
						        } else {
						            this.formData.securityCode = "";
						            return this.$message.error(msg || "操作失败");
						        }
						    })
						    .finally(() => {
						        this.btnLoading = false;
						    });
					}
			        
			    }
			}); 
		 },
		 //暂时不删，合master的时候删除
        onSubmit2() {			
            this.$refs["formData"].validate((valid) => {
                if (valid) {
                    this.btnLoading = true;
                
                    this.selectStyles();
                    this.formData.styles = this.formData.styles.join();
                    this.formData.tags = this.formData.tags.join();
					
					const _url = this.userId
					    ? "/user/register/claim"
					    : "/user/register/registerUser";
					
                    this.$request({
                        url: _url,
                        method: "POST",
                        data: {...this.formData,systime:new Date().getTime()},
                    })
                        .then((res) => {
                            //处理成功回调
                            const { state, msg, result } = res.data;
                            if (state && state == 1) {
                                if(!this.formData.userId){
                                    this.regSuccess();
                                }else{
                                    this.$router.push({
                                        path:'/register/step2',
                                        query:{
                                            userId: this.formData.userId || result.userId,
                                            userType: this.formData.userType || 2,
                                            redirect: this.$router.currentRoute.fullPath,
                                            authType: this.authType, //1：认领，2：认证
											id: result.id || ''
                                        }
                                    });
                                }
                                //注册或认领成功后应该自动登录
                                this.$store.commit( 'setCUSUT', result.session.token || null );
                                if(result.isMaster == 1 || result.masterUsers.length == 1){ //主账号或主账号只有一个主账号
                                    this.$store.commit('setUserInfo',result.masterUsers[0]);
                                    getMenuList();//获取登录用户拥有的菜单权限
                                }
                                
                            } else {
                                this.formData.securityCode = "";
                                return this.$message.error(msg || "操作失败");
                            }
                        })
                        .finally(() => {
                            this.btnLoading = false;
                        });
                }
            });
        },
        regSuccess(){
            this.$alert('<div>\
                欢迎入驻秀动，接下来您可以\
                <div class="MT20"><a class="color-theme" href="/manage/workbench/auth">【秀动认证】</a> 通过认证，才能发布演出</div>\
                <div class="MT10"><a class="color-theme" href="/manage/workbench/site/baseInfo">【完善更多信息】</a>  信息越完善，越能吸引用户</div>\
				<div class="MT10"><a class="color-theme" href="/manage/workbench/accountInfo">【设置登录密码】</a>  尽快修改默认登录密码</div>\
                <div class="MT10"><a class="color-theme" href="/manage/activity/list">【进入小站随便逛逛】</a> </div>\
                </div>', '注册成功', {
                type: 'success',
                confirmButtonText: '确定',
                showCancelButton:false,
                showClose:false,
                dangerouslyUseHTMLString:true,
                customClass:'mpop',
            }).then( () => {
                this.$router.push('/manage/home');
            }).catch(() => {});
        },
    }
}
</script>

<style lang="scss" scoped>
@import './step.scss';
</style>